<!--
Contact form
-->
<template>
  <div class="container px-0">
    <div class="row">
      <div class="col-lg-8">
        <form
          id="contact-form-page"
          ref="contactFormPage"
          action=""
          @submit.prevent="postForm"
        >
          <div class="row">
            <div class="col-lg-6 p-3">
              <input
                v-model="$v.form.lname.$model"
                type="text"
                name="lname"
                maxlength="100"
                class="bb-bottom"
                :class="{ 'is-invalid': $v.form.lname.$error }"
                :placeholder="$t('modules.lname')"
              />
              <input
                v-model="$v.form.companyname.$model"
                type="text"
                name="companyname"
                maxlength="100"
                class="bb-bottom mt-2"
                :class="{ 'is-invalid': $v.form.companyname.$error }"
                :placeholder="$t('modules.cname')"
              />
              <input
                v-model="$v.form.phone.$model"
                type="text"
                name="phone"
                maxlength="20"
                class="bb-bottom mt-2"
                :class="{ 'is-invalid': $v.form.phone.$error }"
                :placeholder="$t('modules.phonenumber')"
              />
            </div>
            <div class="col-lg-6 p-3">
              <input
                v-model="$v.form.fname.$model"
                type="text"
                name="fname"
                maxlength="100"
                class="bb-bottom"
                :class="{ 'is-invalid': $v.form.fname.$error }"
                :placeholder="$t('modules.fname')"
              />
              <input
                v-model="$v.form.email.$model"
                type="text"
                name="email"
                maxlength="100"
                class="bb-bottom mt-2"
                :class="{ 'is-invalid': $v.form.email.$error }"
                :placeholder="$t('modules.mailaddress')"
              />
            </div>
          </div>
          <textarea
            v-model="$v.form.message.$model"
            type="text"
            name="message"
            class="bb mt-2"
            cols="30"
            rows="10"
            :class="{ 'is-invalid': $v.form.message.$error }"
            :placeholder="$t('modules.message')"
          >
          </textarea>
          <div class="clearfix">
            <div class="col float-start py-1">
              <div class="form-check">
                <input
                  type="checkbox"
                  name="subscribe"
                  class="form-check-input"
                  value="yes"
                />
                <label class="form-check-label text-blue fs-6">
                  {{$t('modules.subscribe')}}
                </label>
              </div>
              <div class="form-check">
                <input
                  v-model="$v.form.privacy.$model"
                  type="checkbox"
                  name="privacy"
                  :class="{ 'is-invalid': $v.form.privacy.$error }"
                  class="form-check-input"
                />
                <label class="form-check-label text-blue fs-6">
                  {{$t('modules.agree')}}
                </label>
              </div>
            </div>
            <div class="col float-end py-1">
              <input
                  :value="this.form.source"
                  type="hidden"
                  name="source"
              />
              <button
                type="submit"
                class="btn bg-yellow mt-2"
                :disabled="this.disabledBtn"
              >
                {{$t('modules.send')}}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-lg-4 bg-yellow" v-if="this.configData">
        <div class="text-blue text-center m-3">{{$t('modules.help')}}?</div>
        <div class="text-center m-3">
          <img class="img-circ" src="../../../assets/images/caro-1.jpg" />
        </div>
        <div class="text-blue text-center m-3">
          <strong>{{$t('contact.phone')}}</strong><br />+{{ this.configData.phone }}
          <br /><strong>Email:</strong><br />{{ this.configData.email }}
        </div>
        <div class="text-center m-3">
          <a
            :href="'tel:+' + this.configData.phone"
            type="button"
            class="btn bg-blue2 text-white px-5"
            >{{$t('modules.callnow')}}t<br />
            +{{ this.configData.phone }}</a
          >
        </div>
      </div>
    </div>

    <div
      ref="success-modal"
      class="modal"
      tabindex="-1"
      v-show="this.visible.successModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t('modules.thxmsg')}}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="this.closeModal"
            ></button>
          </div>
          <div class="modal-body">
            <p>{{$t('modules.thxmsg2')}}</p>
          </div>
        </div>
      </div>
    </div>

    <div
      ref="error-modal"
      class="modal"
      tabindex="-1"
      v-show="this.visible.errorModal"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{$t('modules.errormsg')}}</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="this.closeModal"
            ></button>
          </div>
          <div class="modal-body">
            <validation-error
              :errors="validationErrors"
              v-if="validationErrors"
            ></validation-error>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required,
  minLength,
  maxLength,
  email,
  numeric,
} from "vuelidate/lib/validators";
import ValidationError from "@/components/partials/ValidationError";
import CrmApiService from "@/services/CrmApiService";

export default {
  name: "module2",
  components: {
    ValidationError,
  },
  props: {
    configData: { data: [Array] },
  },
  data() {
    return {
      visible: {
        successModal: false,
        errorModal: false,
      },
      disabledBtn: false,
      form: {
        lname: "",
        fname: "",
        email: "",
        phone: "",
        companyname: "",
        message: "",
        subscribe: "yes",
        privacy: null,
        source: this.$route.fullPath
      },
      validationErrors: "",
    };
  },
  validations: {
    form: {
      lname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
      },
      fname: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
      },
      email: {
        required,
        email,
        minLength: minLength(6),
        maxLength: maxLength(100),
      },
      companyname: {
        maxLength: maxLength(100),
      },
      phone: {
        required,
        numeric,
        minLength: minLength(6),
        maxLength: maxLength(20),
      },
      message: {
        required,
        maxLength: maxLength(500),
      },
      privacy: {
        required,
      },
    },
  },
  methods: {
    closeModal() {
      this.visible.successModal = false;
      this.visible.errorModal = false;
    },
    postForm: function () {
      this.$v.form.$touch();

      if (this.$v.form.$error) {
        return;
      }

      this.disabledBtn = true;
      CrmApiService.postContactForm(this.form).then((response) => {
        if (response.data.success) {
          if (response.data.success == 1) {
            this.visible.successModal = true;
            this.$refs.contactFormPage.reset();
          }
        } else {
          if (response.data.errors) {
            this.validationErrors = response.data.errors;
          }
          this.visible.errorModal = true;
          this.disabledBtn = false;
        }
      });
    },
  },
};
</script>

<style scoped></style>
